import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { Api } from '../../../smoothr-web-app-core/api/api';
import { RepositoryService } from '../../../smoothr-web-app-core/services/repository/repository.service';
import { ActivatedRoute, Router } from '@angular/router';
import Order from '../../../smoothr-web-app-core/models/Order';
import { MenuPage } from '../menu/menu.page';
import RepositoryDirective from '../../../smoothr-web-app-core/directives/repository-directive';
import { TranslateService } from '@ngx-translate/core';
import { AlertController, ModalController } from '@ionic/angular';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AnalyticsService } from 'src/smoothr-web-app-core/services/analytics/analytics.service';
import { OrderUtils } from 'src/smoothr-web-app-core/utils/order-utils';
import { PreorderType } from 'src/smoothr-web-app-core/enums/PreorderType';
import { numberToCurrency, sleep } from 'src/smoothr-web-app-core/utils/utils';
import { OrderListAction } from '../../enums/OrderListAction';
import Venue from 'src/smoothr-web-app-core/models/Venue';
import { PaymentModalComponent, PaymentModalResult } from '../../../smoothr-web-app-core/components/payment-modal/payment-modal.component';
// import {PaymentSelectionSyncService} from '../../../smoothr-web-app-core/services/PaymentSelectionSyncService';
import { Subscription } from 'rxjs/internal/Subscription';
import Article from '../../../smoothr-web-app-core/models/Article';
import { PaymentSuccessPage } from '../payment-success/payment-success.page';
import { HomePage } from '../home/home.page';
import { CheckoutModalComponent } from 'src/app/components/checkout-modal/checkout-modal.component';

@Component({
	selector: 'app-multiple-order',
	templateUrl: './multiple-order.page.html',
	styleUrls: ['multiple-order.page.scss'],
})
export class MultipleOrderPage extends RepositoryDirective implements OnInit, OnDestroy {
	static url = 'multiple-orders';
	venue: Venue;
	order: Order;
	notPaid: Order[] = [];
	selectedOrders = new Set<string>();
	selectedOrdersByOthers = new Set<string>();
	loading = false;
	currentTable: string;

	isValid = false;
	totalPrice = 0;
	totalNotPaid = 0;

	promoError: string;
	tip = 0;
	numberToCurrency = numberToCurrency;
	pt = PreorderType;

	constructor(
		public repository: RepositoryService,
		private cdr: ChangeDetectorRef,
		private router: Router,
		private translate: TranslateService,
		private modalCtrl: ModalController,
		private snackbarCtrl: MatSnackBar,
		private route: ActivatedRoute,
		private alertCtrl: AlertController,
		private analytics: AnalyticsService
	) {
		super(repository);
	}

	get hasArticles(): boolean {
		return this.notPaid.length > 0;
	}

	static async navigate(router: Router) {
		await router.navigateByUrl(MultipleOrderPage.url);
	}

	ionViewWillEnter() {
		this.loadOrders();
	}
	onOrder() {
		super.onOrder();
		if (this.order && this.order.orderedArticles && this.order.orderedArticles.length > 0) {
			this.analytics.orderHasProducts();
		}
	}

	onCustomer() {
		super.onCustomer();
		this.loadOrders();
	}

	async loadOrders() {
		if (this.order.table) {
			this.currentTable = this.order.table;
		}
		this.loading = true;
		try {
			const venue = await this.repository.getVenue(this.order.venue);
			const articleList = [];
			for (const category of venue.articleCategories) {
				for (const article of category.articles) {
					articleList.push(article);
				}
			}
			const res = (await Api.getOrdersByTable(this.order.venue, this.order.table)).data.map(it => this.reloadOrder(it, articleList));
			this.notPaid = res.filter(it => !it.isPayed).filter(v => v.tableNumber === this.order.tableNumber);
			this.totalNotPaid = this.notPaid.reduce((prev, curr) => {
				return prev + OrderUtils.orderTotalPrice(curr, true, true);
			}, 0);
		} catch (err) {
			console.log(err);
			await sleep(1000);
			this.loading = false;
			this.cdr.detectChanges();
		}
		this.loading = false;
	}

	reloadOrder(order: Order, articleList: Article[]) {
		order.orderedArticles = order.orderedArticles.map(oa => {
			oa.article.assets = articleList.find(article => oa.article._id === article._id)?.assets;
			return oa;
		});
		return order;
	}

	addArticle() {
		MenuPage.navigate(this.router);
	}

	ngOnInit() {
		super.ngOnInit();
		this.analytics.openOrderContent();
	}

	ngOnDestroy() {
		super.ngOnDestroy();
	}

	validPrice() {
		console.log('TOTAL PRICE', this.totalPrice);
		return this.totalPrice > 0;
	}

	formattedTotalNotPaid(): string {
		return numberToCurrency(this.totalNotPaid, this.venue?.currency);
	}

	async goToPayment() {
		this.loading = true;
		const res = await PaymentModalComponent.show(this.modalCtrl, this.venue, this.notPaid, this.customer);

		switch (res.result) {
			case PaymentModalResult.SUCCESS:
				await PaymentSuccessPage.navigate(
					this.router,
					this.notPaid.map(it => it._id),
					res.payment._id
				);
				break;
			case PaymentModalResult.ERROR:
				this.snackbarCtrl.open(this.translate.instant('payment_modal.error'), null, {
					duration: 2000,
				});
				break;
		}
		this.loading = false;
	}

	backToMenu() {
		MenuPage.navigate(this.router);
	}
}
