import { MultipleOrderPage } from './../multiple-order/multiple-order.page';
import { ModalController } from '@ionic/angular';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Component, OnInit, ViewChild } from '@angular/core';
import { RepositoryService } from '../../../smoothr-web-app-core/services/repository/repository.service';
import { Router, ActivatedRoute } from '@angular/router';
import {} from 'google.maps';
import { AppComponent } from '../../app.component';
import RepositoryDirective from '../../../smoothr-web-app-core/directives/repository-directive';
import { environment } from '../../../environments/environment';
import { AngularFireAnalytics } from '@angular/fire/analytics';
import { MenuPage } from '../menu/menu.page';
import { PreorderType } from 'src/smoothr-web-app-core/enums/PreorderType';
import { sleep, venueAcceptsOrders } from 'src/smoothr-web-app-core/utils/utils';
import Venue from 'src/smoothr-web-app-core/models/Venue';
import Address from 'src/smoothr-web-app-core/models/Address';
import { ScanQrModal } from 'src/app/components/scan-qr/scan-qr.component';
import { QRCodeComponent, QRCodeModule } from 'angular2-qrcode';
import Table from 'src/smoothr-web-app-core/models/Table';
import { Api } from 'src/smoothr-web-app-core/api/api';
import { SelectTableModalComponent } from 'src/app/components/select-table-modal/select-table-modal.component';
import { TranslateService } from '@ngx-translate/core';
enum Language {
	GreatBritain = 'en',
	German = 'de'
}
@Component({
	selector: 'app-home',
	templateUrl: './home.page.saray.html',
	styleUrls: ['./home.page.saray.scss'],
})
export class HomePage extends RepositoryDirective implements OnInit {
	static url = 'home';
	static scanUrl = 'scan-qr/table/:tableNumber';
	environment = environment;
	preorderTypes = [PreorderType.INSIDE, PreorderType.TAKE_AWAY];
	languageEnum = Language;

	@ViewChild('backgroundVideo', { static: true }) video: HTMLVideoElement;

	loading = false;

	constructor(
		protected repository: RepositoryService,
		private router: Router,
		private analytics: AngularFireAnalytics,
		private snackbarCtrl: MatSnackBar,
		private modalCtrl: ModalController,
		private route: ActivatedRoute,
		public translate: TranslateService
	) {
		super(repository);
	}

	static async navigate(router: Router, repository?: RepositoryService) {
		if (repository) {
			repository.resetVenue();
			repository.address.emit(null);
			repository.order.emit(null);
			repository.verifiedOrder.emit(null);
			repository.payment.emit(null);
		}
		await router.navigateByUrl(HomePage.url);
	}

	largeScreen(): boolean {
		return AppComponent.largeScreen;
	}

	ngOnInit() {
		super.ngOnInit();
		const tableNumber = this.route.snapshot.paramMap.get('tableNumber');
		if(tableNumber) {
			this.openQrScan(PreorderType.INSIDE, tableNumber)
		}
	}

	ionViewDidEnter() {
		if (history.state.navigationId === 1) {
			this.repository.order.emit(null);
		}
		this.repository.verifiedOrder.emit(null);
	}

	async goToMenu(type: PreorderType) {
		this.locateAndShowStores(type);
	}
	async locateAndShowStores(preorderType: PreorderType) {
		this.loading = true;
		const venue = (await this.repository.getAllVenues()).find(ven => {
			return venueAcceptsOrders(ven, preorderType);
		});
		if (!venue) {
			console.log('No available venues');
			this.loading = false;
			return;
		}
		if (preorderType === PreorderType.INSIDE) {
			this.openQrScan(preorderType);
			return;
		}
		await this.loadVenueAndCreateOrder(venue, null, preorderType);
	}
	async openQrScan(preorderType: PreorderType, tableNumber: string = null) {
		this.loading = true;
		try {

			const venue = (await this.repository.getAllVenues()).find(ven => {
				return venueAcceptsOrders(ven, preorderType);
			});
			const tableNumberFromModal = tableNumber || (await ScanQrModal.show(this.modalCtrl, venue));
			if (!tableNumberFromModal) {
				return;
			}
			const tableObj = (await Api.getTable(venue._id, tableNumberFromModal)).data;
			if (tableObj) {
				const orders = await Api.getOrdersByTable(venue._id, tableObj._id);
				const loadedVenue = await this.repository.getVenue(venue._id);
				this.repository.createOrder(loadedVenue, null, preorderType, null);
				sleep(100);
				this.order.tableNumber = tableObj.number;
				this.order.table = tableObj._id;
				this.repository.order.emit(this.order);
				if(orders.data.filter(it => it.tableNumber == tableObj.number).filter(it => !it.isPayed).length > 0 && !this.authLogin) {
					await MultipleOrderPage.navigate(this.router);
					this.loading = false;
					return;
				}
				await MenuPage.navigate(this.router);
				await SelectTableModalComponent.show(this.modalCtrl, this.order);
			}
			this.loading = false;

		} catch (e) {
			console.log('Error in table selection');
			this.loading = false;
			return;
		}
	}
	async loadVenueAndCreateOrder(venue: Venue, address: Address, preorderType: PreorderType) {
		console.log('loadVenueAndCreateOrder(', venue, address, preorderType, ')');
		const loadedVenue = await this.repository.getVenue(venue._id);
		this.repository.createOrder(loadedVenue, address, preorderType, null);
		await MenuPage.navigate(this.router);
		this.loading = false;
	}
	changeLang(language: string) {
		this.translate.setDefaultLang(language);
		this.translate.use(language);
	}
}
