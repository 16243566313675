import {apiEnvironment} from '../smoothr-web-app-core/environments/apiEnvironment';
import 'zone.js/dist/zone-error';

export const sarayCustomerGroup = 'saray';
export const customerGroup = sarayCustomerGroup;

const supportEmail = {
	saray: 'saray@smoothr.de'
};
const firebaseConfig = {
	saray:  {
		apiKey: "AIzaSyBx3rlbR5y81d9sWT4DJEfDlqFyqUmXaz0",
		authDomain: "dev-env-216009.firebaseapp.com",
		databaseURL: "https://dev-env-216009.firebaseio.com",
		projectId: "dev-env-216009",
		storageBucket: "dev-env-216009.appspot.com",
		messagingSenderId: "1004449889395",
		appId: "1:1004449889395:web:a5b564d0dc311471e0fe06",
		measurementId: "G-3CB910K54V"
	  }
};
export const environment = {
	customerGroup,
	webAppUrl: window.location.protocol + '//' + window.location.host,
	placesApiKey: 'AIzaSyDpFtOJzMtgBZURnrVxYflqLxf8ej2tljY',
	SUPPORT_EMAIL: supportEmail[customerGroup],
	firebaseConfig: firebaseConfig[customerGroup],
	countryList: ['de'],
	APAY_MERCHANT_ID: 'merchant.smoothr.' + customerGroup + '-dev',
	...apiEnvironment
};
