import { Component, OnInit, ViewChild } from '@angular/core';
import { RepositoryService } from '../../../smoothr-web-app-core/services/repository/repository.service';
import ArticleCategory from '../../../smoothr-web-app-core/models/ArticleCategory';
import { ActivatedRoute, Router } from '@angular/router';
import { ToOrderButtonComponent } from '../../components/to-order-button/to-order-button.component';
import { AlertController, IonSearchbar, ModalController } from '@ionic/angular';
import { ModalInfoComponent } from '../../components/modal-info/modal-info.component';
import Article from '../../../smoothr-web-app-core/models/Article';
import { AppComponent } from '../../app.component';
import { PreorderType } from '../../../smoothr-web-app-core/enums/PreorderType';
import * as moment from 'moment';
import { MatSnackBar } from '@angular/material/snack-bar';
import { OrderPage } from '../order/order.page';
import { TranslateService } from '@ngx-translate/core';
import { OrderUtils } from '../../../smoothr-web-app-core/utils/order-utils';
import { OrderType } from '../../../smoothr-web-app-core/enums/OrderType';
import RepositoryDirective from '../../../smoothr-web-app-core/directives/repository-directive';
import { environment } from '../../../environments/environment';
import { SelectVenueModalComponent, SelectVenueModalResult } from '../../components/select-venue-modal/select-venue-modal.component';
import {
	axiosErrorToMessage,
	getAvailability,
	getSlots,
	getSlotsByOrder,
	isVenueOpen,
	numberD,
	sleep,
} from '../../../smoothr-web-app-core/utils/utils';
import { AnalyticsService } from '../../../smoothr-web-app-core/services/analytics/analytics.service';
import { OnlyNextDayModalComponent } from '../../components/only-next-day-modal/only-next-day-modal.component';
import { ScanQrModal } from 'src/app/components/scan-qr/scan-qr.component';
import Venue from '../../../smoothr-web-app-core/models/Venue';
import Fuse from 'fuse.js';
import { HomePage } from '../home/home.page';

@Component({
	selector: 'app-menu',
	templateUrl: './menu.page.html',
	styleUrls: ['menu.page.scss'],
})
export class MenuPage extends RepositoryDirective implements OnInit {
	static url = 'menu';

	@ViewChild(ToOrderButtonComponent, { static: false }) toOrderButton;
	@ViewChild('categoryList', { static: false }) categoryList;
	@ViewChild('articleContent', { static: false }) articleList;
	@ViewChild(IonSearchbar, { static: false }) searchBar;

	selectedCategory: ArticleCategory = null;
	scrollEvents = true;
	loading = false;
	searchTerm = '';
	categories: ArticleCategory[] = [];
	moment = moment;
	isValid = false;
	orderUtils = OrderUtils;
	differenceToMvo: number;
	attempt = 1;
	venueOpen = true;
	venuePanic = false;
	environment = environment;
	constructor(
		private translate: TranslateService,
		protected repository: RepositoryService,
		private modalCtrl: ModalController,
		private snackbarCtrl: MatSnackBar,
		private route: ActivatedRoute,
		private alertCtrl: AlertController,
		private router: Router,
		private analytics: AnalyticsService
	) {
		super(repository);
	}

	get index(): number {
		return this.categories.findIndex(category => category._id === this.selectedCategory._id);
	}

	static async navigate(router: Router) {
		await router.navigateByUrl(MenuPage.url);
	}

	ngOnInit() {
		super.ngOnInit();
		console.log(this.order);
		switch (window.location.pathname) {
			case '/' + OrderPage.url:
				break;
			case '/' + OrderPage.paymentCancelUrl:
				console.log(this.translate.instant('order.payment_cancel'));
				this.snackbarCtrl.open(this.translate.instant('order.payment_cancel'), null, {
					duration: 2000,
				});
				break;
			case '/' + OrderPage.paymentFailUrl:
				console.log(this.translate.instant('order.payment_fail'));
				this.snackbarCtrl.open(this.translate.instant('order.payment_fail'), null, {
					duration: 5000,
				});
				break;
		}
		this.loadVenue();
	}

	async loadVenue() {
		this.loading = true;
		try {
			this.repository.venue.emit(await this.repository.getVenue(this.order?.venue ?? environment.customerGroup + '_master'));
		} catch (e) {
			console.error(e);
		}
		this.loading = false;
	}

	largeScreen(): boolean {
		return AppComponent.largeScreen;
	}

	async scrollTo(index: number) {
		if (this.categories.length - 1 < index || index < 0) {
			return;
		}
		this.scrollEvents = false;
		this.selectedCategory = this.categories[index];
		await this.scrollArticleListTo(index);
		await this.scrollCategoryListTo(index);
		this.scrollEvents = true;
		return;
	}

	async onScroll(event) {
		const categoryElements = [...this.articleList.el.children];
		const categoryIndex = categoryElements
			.map(el => el.offsetTop)
			.map((value, index, array) => {
				return (
					value <= event.detail.currentY &&
					((index < array.length - 1 && event.detail.currentY < array[index + 1]) || index === array.length - 1)
				);
			})
			.findIndex(value => value);
		let prevIndex = -1;

		if (this.selectedCategory) {
			prevIndex = this.categories.findIndex(category => category._id === this.selectedCategory._id);
		}
		if (prevIndex === 0 && categoryIndex === -1 && this.categories[0]) {
			this.selectedCategory = this.categories[0];
			await this.scrollCategoryListTo(categoryIndex);
			return;
		}
		if (prevIndex === categoryIndex) {
			return;
		}
		if (this.scrollEvents) {
			this.selectedCategory = this.categories[categoryIndex];
			await this.scrollCategoryListTo(categoryIndex);
		}
	}

	async openModal(item: Article) {
		if (this.loading) {
			return;
		}
		if (!this.venueOpen || this.venuePanic) {
			this.snackbarCtrl.open(this.translate.instant('menu_page.venue_panic_or_closed'), null, {
				duration: 2000,
			});
			return;
		}

		if (!this.order) {
			await OnlyNextDayModalComponent.show(this.modalCtrl);
			const result = await SelectVenueModalComponent.show(this.modalCtrl);
			if (result === undefined) {
				this.loading = false;
				return;
			}
			const venue = await this.repository.getVenue(result.venue._id);

			if (!result.preorderType && !this.order?.table && this.order?.preorder.type !== PreorderType.INSIDE) {
				await this.openQrScan(venue, item, result);
				return;
			} else {
				this.repository.createOrder(result.venue, result.address, result.preorderType, null);
				const allArticles = [];
				venue.articleCategories.forEach(cat => allArticles.push(...cat.articles));
				const articleInVenue = allArticles.find(
					art => art._id === item._id || (art.masterId === item.masterId && item.masterId) || art.masterId === item._id
				);
				this.loading = false;
				if (!articleInVenue) {
					return;
				}
				item = articleInVenue;
			}
		}
		await this.openSelectedProduct(item);
	}

	async openQrScan(venue: Venue, item: Article, select: SelectVenueModalResult) {
		const table = await ScanQrModal.show(this.modalCtrl, venue);
		if (!table) {
			return;
		}
		try {
			this.repository.createOrder(venue, select.address, select.preorderType, null);
			const allArticles = [];
			venue.articleCategories.forEach(cat => allArticles.push(...cat.articles));
			const articleInVenue = allArticles.find(
				art => art._id === item._id || (art.masterId === item.masterId && item.masterId) || art.masterId === item._id
			);
			this.loading = false;
			if (!articleInVenue) {
				return;
			}
			await this.openSelectedProduct(articleInVenue);
		} catch (e) {
			console.error(e);
			this.snackbarCtrl.open(this.translate.instant('menu_page.venue_panic_or_closed'), null, {
				duration: 2000,
			});
			return;
		}
	}

	async onSearchTermChanged(event) {
		this.searchTerm = event.detail.value;
		this.reloadCategories();
		if (!this.searchTerm) {
			this.selectedCategory = this.categories[0];
			await this.scrollCategoryListTo(0);
			await this.scrollArticleListTo(0);
		}
	}

	async openSelectedProduct(item: Article) {
		const res = await ModalInfoComponent.show(this.modalCtrl, item, this.order.preorder?.type);
		if (res) {
			OrderUtils.addToOrder(this.order, res, this.analytics);
			this.repository.order.emit(this.order);
		}
	}
	reloadCategories() {
		if (!this.venue) {
			this.categories = [];
			return;
		}
		if (this.searchTerm !== null && this.searchTerm !== '') {
			this.categories = this.venue.articleCategories
				.filter(category => !category.hidden)
				.map(category => {
					const cat: ArticleCategory = JSON.parse(JSON.stringify(category));
					const arr = cat.articles.map(it => ({
						cat,
						art: it,
						tags: it.tags.map(it => it.name),
					}));
					const fuse = new Fuse(arr, {
						isCaseSensitive: false,
						findAllMatches: true,
						includeMatches: true,
						includeScore: false,
						useExtendedSearch: true,
						minMatchCharLength: 1,
						shouldSort: true,
						threshold: 0.2,
						location: 0,
						distance: 100,
						keys: ['art.name.de', 'art.name.en', 'cat.name.de', 'cat.name.en', 'tags.de', 'tags.en'],
					});
					cat.articles = fuse.search(this.searchTerm).map(result => result.item.art);
					return cat;
				})
				.filter(it => it.articles.length > 0);
			this.selectedCategory = this.categories[0];
			return;
		}
		this.categories = this.venue.articleCategories.filter(category => !category.hidden);

		return;
	}
	validate() {
		if (!this.venue || !this.order) {
			this.isValid = false;
			return;
		}
		const mov = this.order.preorder?.type === PreorderType.DELIVERY ? numberD(this.venue.movDelivery) : 0;
		const orderValue = OrderUtils.articleGroupsTotalPrice(
			this.order.orderedArticles,
			this.order.type,
			this.order.preorder?.type,
			this.order.terminalorder?.type
		);
		this.differenceToMvo = orderValue - mov;
		this.isValid = this.differenceToMvo >= 0;
	}

	onVenue() {
		super.onVenue();
		if (!this.venue) {
			this.loadVenue();
			return;
		}
		this.reloadCategories();
		this.reloadPanicAndOpening();
		this.selectedCategory = this.venue && this.categories.length > 0 ? this.categories[0] : null;
		this.validate();
	}

	async reloadPanicAndOpening() {
		if (!this.venue) {
			this.venueOpen = false;
			this.venuePanic = false;
			return;
		}
		if ((this.order?.type ?? OrderType.STANDARD) === OrderType.STANDARD) {
			this.venueOpen = isVenueOpen(this.venue);
		} else {
			this.venueOpen = (await getSlotsByOrder(this.venue, this.order)).length > 0;
		}
		this.venuePanic = !this.venue?.isServiceActivated || this.venue?.panicEndAt.isAfter(moment());
		console.log({ open: this.venueOpen, panic: this.venuePanic });
	}

	onVenueError(error) {
		super.onVenueError(error);
		this.snackbarCtrl.open(axiosErrorToMessage(this.translate, error));
		this.loading = false;
		this.snackbarCtrl.open(
			this.translate.instant('menu_page.venue_timed_out'),
			this.translate.instant('menu_page.venue_timed_out_action')
		);
	}

	onOrder() {
		super.onOrder();
		this.validate();
	}

	private async scrollCategoryListTo(index: number) {
		if (
			index < 0 ||
			!this.categoryList?.nativeElement?.children[index] ||
			(!this.categoryList?.nativeElement?.scrollTo && !this.categoryList?.nativeElement?.scrollLeft)
		) {
			return;
		}
		await sleep(100);

		this.categoryList.nativeElement.scrollTo(this.categoryList.nativeElement.children[index].offsetLeft - 25, 0);
		await sleep(100);
	}

	private async scrollArticleListTo(index: number) {
		await this.articleList.scrollToPoint(0, this.articleList.el.children[index].offsetTop + 1, 300);
	}
	goHome() {
		if (!this.authLogin) {
			HomePage.navigate(this.router);
		}
	}
}
