import {ChangeDetectorRef, Component} from '@angular/core';
import {Api} from '../../../smoothr-web-app-core/api/api';
import {RepositoryService} from '../../../smoothr-web-app-core/services/repository/repository.service';
import {Router} from '@angular/router';
import {PaymentStatus} from '../../../smoothr-web-app-core/models/PaymentStatus';
import * as moment from 'moment';
import Order from '../../../smoothr-web-app-core/models/Order';
import {MenuPage} from '../menu/menu.page';
import RepositoryDirective from '../../../smoothr-web-app-core/directives/repository-directive';

@Component({
	selector: 'app-my-orders',
	templateUrl: './my-orders.page.html',
	styleUrls: ['my-orders.page.scss']
})
export class MyOrdersPage extends RepositoryDirective {
	static url = 'my-orders';

	orders: Order[] = [];
	loading = false;

	constructor(
		public repository: RepositoryService,
		private cdr: ChangeDetectorRef,
		private router: Router
	) {
		super(repository);
	}

	get openOrders(): Order[] {
		return this.orders.filter(value =>
			moment(value.orderAt).add(30, 'minutes').isSameOrAfter(moment())
		);
	}

	get doneOrders(): Order[] {
		return this.orders.filter(value =>
			moment(value.orderAt).add(30, 'minutes').isBefore(moment())
		);
	}

	static navigate(router: Router) {
		router.navigateByUrl(MyOrdersPage.url);
	}

	onCustomer() {
		super.onCustomer();
		this.loadOrders();
	}

	loadOrders() {
		this.orders = [];
		if (!this.customer) {
			console.log('user not loaded');
			this.cdr.detectChanges();
			return;
		}
		Api.getCustomerOrders(this.customer.uid)
			.then(res => {
				this.orders = res.data.withPayment
					.filter(
						order =>
							order.referenceOrder === null ||
							order.referenceOrder === undefined ||
							order.referenceOrder === ''
					)
					.reverse();
				this.loading = false;
				this.cdr.detectChanges();
			})
			.catch(err => {
				console.log(err);
				this.loading = false;
				this.cdr.detectChanges();
			});
	}

	goBack() {
		MenuPage.navigate(this.router);
	}
}
