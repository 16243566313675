import {apiEnvironment} from '../smoothr-web-app-core/environments/apiEnvironment';

export const sarayCustomerGroup = 'saray';
export const customerGroup = sarayCustomerGroup;

const supportEmail = {
	saray: 'saray@smoothr.de'
};
const firebaseConfig = {
	saray: {
		apiKey: "AIzaSyB1Y4wG6BoFE2hi8irI30ofKfscXoEPPDw",
		authDomain: "prod-env-smoothr.firebaseapp.com",
		databaseURL: "https://prod-env-smoothr.firebaseio.com",
		projectId: "prod-env-smoothr",
		storageBucket: "prod-env-smoothr.appspot.com",
		messagingSenderId: "640058019537",
		appId: "1:640058019537:web:24b128fc09c228c8cbfff0",
		measurementId: "G-EMZJW260K6"
	}
};
export const environment = {
	customerGroup,
	webAppUrl: window.location.protocol + '//' + window.location.host,
	placesApiKey: 'AIzaSyAhv3uDQY3WZMcfRia2wgf928h-jlHlXHA',
	baseUrl: 'https://api.smoothr.de',
	SUPPORT_EMAIL: supportEmail[customerGroup],
	firebaseConfig: firebaseConfig[customerGroup],
	countryList: ['de'],
	APAY_MERCHANT_ID: 'merchant.smoothr.' + customerGroup,
	...apiEnvironment
};
